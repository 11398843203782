import * as VoteStyles from './VoteWidget.module.scss'
import React, { useContext, useEffect, useState, useReducer } from 'react'
import { LoginContext, hashEmail, unhashEmail } from '../../../lib/utils'
import { useMutation } from '@apollo/client'
import { navigate } from 'gatsby'
import {
  VoteBusiness,
  type IVoteBusinessData
} from '../../../graphql/queries/VoteBusiness'
import {
  ThankYou,
  VoteConfirmed,
  VoteError,
  StageOne,
  ConfirmYourVote
} from './Partials'

export interface AwardCategory {
  id: string
  title: string
  image: string
  description: string
  finalists?: {
    meta_a: string
    meta_b: string
    meta_c: string
    meta_d: string
    meta_e: string
  }
}

interface VoteWidgetProps {
  categories: AwardCategory[]
  siteName: string
  siteUrl: string
}

export interface ReducerEvent {
  name: string
  value: string
}

export interface ReducerState {
  emailAddress?: string
  cat?: string
  vote?: 'meta_a' | 'meta_b' | 'meta_c' | 'meta_d' | 'meta_e'
  siteName: string
  spamdetect?: string
}

const VoteWidget: React.FC<VoteWidgetProps> = ({
  categories,
  siteUrl,
  siteName
}) => {
  const { usr } = useContext(LoginContext)
  const [loggedIn, setLoggedIn] = useState<boolean>(false)
  // eslint-disable-next-line node/no-unsupported-features/node-builtins
  const searchParams = new URLSearchParams(
    typeof window !== 'undefined' ? location.search : undefined
  )
  const category = searchParams.get('c')
  const uid = searchParams.get('uid')

  const [selectedCategory, setSelectedCategory] = useState<string>(
    category ?? ''
  )
  const [showChangeCategory, setShowChangeCategory] = useState<boolean>(true)
  const [selectedCategoryObject, setSelectedCategoryObject] = useState<
    AwardCategory | undefined
  >()
  const [voteError, setVoteError] = useState<string | undefined>()
  const [voteConfirmed, setVoteConfirmed] = useState<boolean>(false)
  const [uniqueLinkRequested, setUniqueLinkRequested] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const formReducer = (state: ReducerState, event: ReducerEvent) => {
    return {
      ...state,
      [event.name]: event.value
    }
  }

  const InitialState: ReducerState = Object({
    siteName: siteName
  })

  const [formData, setFormData] = useReducer(formReducer, InitialState)

  const [voteBusiness] = useMutation<IVoteBusinessData>(VoteBusiness, {
    variables: { payload: JSON.stringify(formData) }
  })

  useEffect(() => {
    if (usr && usr.viewer?.hasOwnProperty('name')) {
      setFormData({
        name: 'emailAddress',
        value: usr.viewer.email ?? ''
      })
      setLoggedIn(true)
    }
  }, [usr])

  useEffect(() => {
    if (uid !== null) {
      const split = uid.split('-')

      const fields = ['emailAddress', 'cat', 'vote', 'county', 'spamdetect']
      split.forEach((s, i) =>
        setFormData({
          name: fields[i],
          value: unhashEmail(s)
        })
      )

      setSelectedCategory(unhashEmail(split[1]))
    }
  }, [uid])

  useEffect(() => {
    if (selectedCategory.length > 0) {
      const cat = categories.filter(c => c.id === selectedCategory)
      setSelectedCategoryObject(cat.length > 0 ? cat[0] : undefined)
      setShowChangeCategory(false)
      setFormData({
        name: 'cat',
        value: selectedCategory
      })
    }
  }, [selectedCategory])

  const sendToDatabase = async () => {
    setLoading(true)
    try {
      const { data } = await voteBusiness()
      if (data?.voteBusiness) {
        const res = JSON.parse(data?.voteBusiness)
        if (res.success === 0) {
          throw new Error(res.message)
        }
      }
    } catch (e: any) {
      if (e instanceof Error) {
        if (e.message === 'alreadyVoted') {
          setVoteError(
            'Sorry, it appears that you’ve already voted in this category. Please select a different category'
          )
        } else if (e.message === 'unspecifiedError') {
          setVoteError(
            'Sorry, there was an unspecified error whilst trying to submit your vote. Please try again by clicking'
          )
        } else if (e.message === 'invalidEmail') {
          setVoteError(
            'Sorry, there appears to be a problem with the email address supplied. Please try again by clicking'
          )
        } else if (e.message === 'deadlinePassed') {
          setVoteError(
            'The deadline has now passed! Please check back soon to see the winners!'
          )
        }
      } else {
        // Most likely means mysql is down.
        setVoteError('Sorry, there was an error submitting your details.')
      }
    } finally {
      setVoteConfirmed(true)
      setLoading(false)
    }
  }

  const handleVote = async () => {
    setLoading(true)
    if (
      formData.cat &&
      formData.emailAddress &&
      formData.siteName &&
      formData.vote &&
      formData.spamdetect
    ) {
      const uniqueString = encodeURIComponent(
        `${hashEmail(formData.emailAddress)}-${hashEmail(
          formData.cat
        )}-${hashEmail(formData.vote)}-${hashEmail(
          formData.siteName
        )}-${hashEmail(formData.spamdetect)}`
      )

      try {
        const reqBody = new FormData()
        reqBody.append('link', `/awards/vote/?uid=${uniqueString}`)
        reqBody.append('email', formData.emailAddress ?? '')
        const requestLink = await fetch(
          `${siteUrl}/wp-json/muddy/send-vote-confirmation`,
          {
            method: 'post',
            body: reqBody
          }
        )
          .then(response => {
            return response.json()
          })
          .catch(e => {
            return e.message
          })

        if (requestLink.success !== true) {
          throw new Error(
            'It seems as though the email address was invalid. Could you try again?'
          )
        } else {
          if (requestLink.data === 'emailVerified') {
            navigate(`/awards/vote/?uid=${uniqueString}`, { replace: true })
          } else {
            setUniqueLinkRequested(true)
          }
        }
      } catch (e: any) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleFieldChange = (field: any) => {
    setFormData({
      name: field.target.name,
      value: field.target.value
    })
  }

  const confirmVote = () => {
    sendToDatabase()
  }

  const resetVoteData = () => {
    setVoteConfirmed(false)
    setUniqueLinkRequested(false)
    setSelectedCategory('')
    setSelectedCategoryObject(undefined)
    setFormData({
      name: 'cat',
      value: ''
    })
    setFormData({
      name: 'vote',
      value: ''
    })
    setFormData({
      name: 'emailAddress',
      value: ''
    })
    setShowChangeCategory(false)
    if (typeof window !== 'undefined')
      navigate(location.pathname, { replace: true })
  }

  return (
    <div className={VoteStyles.Wrapper}>
      <div className={VoteStyles.Nominate}>
        <>
          {uid || voteConfirmed ? (
            <>
              {voteConfirmed ? (
                <>
                  {!voteError ? (
                    <ThankYou
                      selectedCategoryObject={selectedCategoryObject}
                      siteName={siteName}
                      formData={formData}
                      resetVoteData={resetVoteData}
                      siteUrl={siteUrl}
                    />
                  ) : (
                    <VoteError {...{ voteError, resetVoteData }} />
                  )}
                </>
              ) : (
                <ConfirmYourVote
                  {...{
                    formData,
                    selectedCategoryObject,
                    siteName,
                    confirmVote
                  }}
                />
              )}
            </>
          ) : (
            <>
              {!uniqueLinkRequested ? (
                <StageOne
                  {...{
                    selectedCategoryObject,
                    showChangeCategory,
                    handleVote,
                    setSelectedCategory,
                    handleFieldChange,
                    setFormData,
                    formData,
                    loggedIn,
                    siteName,
                    categories,
                    selectedCategory,
                    setShowChangeCategory,
                    loading
                  }}
                />
              ) : (
                <VoteConfirmed />
              )}
            </>
          )}
        </>
      </div>
    </div>
  )
}

export default VoteWidget
