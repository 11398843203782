import React from 'react'
import * as VoteStyles from '../VoteWidget.module.scss'
import Hr from '../../../../components/Hr/Hr'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import SocialShare from '../SocialShare'
import { AwardCategory, ReducerState } from '../VoteWidget'

type ThankYouProps = {
  siteUrl: string
  selectedCategoryObject: AwardCategory | undefined
  formData: ReducerState
  resetVoteData: () => void
  siteName: string
}

const ThankYou: React.FC<ThankYouProps> = ({
  siteUrl,
  resetVoteData,
  selectedCategoryObject,
  formData,
  siteName
}) => {
  return (
    <>
      <h2 className={VoteStyles.Boomshell}>Thank you!</h2>
      <p>You voted for </p>
      <p className={VoteStyles.VoteeName}>
        {formData.vote !== undefined &&
          selectedCategoryObject &&
          selectedCategoryObject.finalists &&
          selectedCategoryObject.finalists[formData.vote]}
      </p>
      <p className={VoteStyles.VoteeCategory}>
        as
        {` ${
          formData.cat && selectedCategoryObject?.title.replace('&amp;', '&')
        } in ${siteName.replace('&amp;', '&')}`}
      </p>
      <p>Your vote has been registered.</p>
      <p>
        To vote for another business in a different category, click{` `}
        <span
          style={{
            color: '#d41a50',
            cursor: 'pointer',
            textDecoration: 'underline'
          }}
          onClick={() => {
            resetVoteData()
          }}
        >
          here.
        </span>
      </p>
      <Hr />
      <div className={VoteStyles.StepThree}>
        <h3>What&apos;s next?</h3>
        <p className={VoteStyles.Step}>
          <span className={VoteStyles.Number}>1.</span>SHARE ON SOCIAL
        </p>
        <SocialShare siteUrl={siteUrl} />

        <p className={VoteStyles.Step}>
          <span className={VoteStyles.Number}>2.</span>WIN a two-night stay at
          THE PIG hotel of your choice, worth £1,250
        </p>
        <StaticImage
          src={`../../../../static/images/awards/rt2024.jpg`}
          width={400}
          alt={'£1250 TWO NIGHT STAY AT THE PIG HOTEL OF YOUR CHOICE'}
        />
        <p>
          Because if you’ve voted and shared, your good karma needs rewarding.
        </p>
        <a
          href={
            'https://muddystilettos.co.uk/reader-treats/win-two-night-stay-the-pig-hotel-of-your-choice/'
          }
          className={VoteStyles.Button}
        >
          Click & win
        </a>
        <div className={VoteStyles.Spacer} />
        <p className={VoteStyles.Step}>
          <span className={VoteStyles.Number}>3.</span>
          LIVE RESULTS!{' '}
        </p>
        <p>
          <Link to={`/awards/live-results/`}>Follow the voting</Link> live with
          our fabulous undulating cocktail glasses. Cheers!
        </p>
      </div>
    </>
  )
}

export default ThankYou
