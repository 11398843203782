import React, { useEffect } from 'react'
import * as VoteStyles from '../VoteWidget.module.scss'
import { ReducerState, AwardCategory } from '../VoteWidget'
import LoadingLogomark from '../../../LoadingLogomark/LoadingLogomark'

type ConfirmVoteProps = {
  selectedCategoryObject: AwardCategory | undefined
  formData: ReducerState
  siteName: string
  confirmVote: () => void
}

const ConfirmYourVote: React.FC<ConfirmVoteProps> = ({ confirmVote }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      confirmVote()
    }, 3000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <div style={{ padding: '30px 0' }}>
      <h2 className={VoteStyles.Boomshell}>Please wait</h2>
      <LoadingLogomark show={true} complete={false} />

      <p>We are just confirming your vote, it won&apos;t take a moment.</p>
    </div>
  )
}

export default ConfirmYourVote
