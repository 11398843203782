import React from 'react'
import * as VoteStyles from '../VoteWidget.module.scss'
import { Link } from 'gatsby'

const VoteConfirmed: React.FC = () => {
  return (
    <>
      <h2 className={VoteStyles.Boomshell}>Thank you!</h2>
      <p>
        <strong>
          One more thing... Your vote doesn’t count until you verify it!
        </strong>
      </p>
      <p>
        Simply check your email (including spam and junk folders) and click the
        &apos;verify your vote&apos; button – it only takes a second and means
        your vote will be counted.
      </p>
      <p>Thank you for supporting your favourite local business!</p>
      <p>
        PS If you want to vote in any other categories, click{' '}
        <Link to="/vote">here</Link> and repeat the process.
      </p>
    </>
  )
}

export default VoteConfirmed
