import React, { useEffect, useState } from 'react'
import * as VoteStyles from '../VoteWidget.module.scss'
import classNames from 'classnames'
import { AwardCategory, ReducerEvent, ReducerState } from '../VoteWidget'
import { Link } from 'gatsby'
import SpamDetector from '../../SpamDetector/SpamDetector'

type StageOneProps = {
  selectedCategoryObject: AwardCategory | undefined
  showChangeCategory: boolean
  handleVote: () => Promise<void>
  setSelectedCategory: React.Dispatch<React.SetStateAction<string>>
  handleFieldChange: (field: any) => void
  setFormData: React.Dispatch<ReducerEvent>
  formData: ReducerState
  loggedIn: boolean
  siteName: string
  categories: AwardCategory[]
  selectedCategory: string
  setShowChangeCategory: React.Dispatch<boolean>
  loading: boolean
}

const StageOne: React.FC<StageOneProps> = ({
  selectedCategoryObject,
  siteName,
  loggedIn,
  handleFieldChange,
  showChangeCategory,
  selectedCategory,
  setSelectedCategory,
  setShowChangeCategory,
  categories,
  formData,
  setFormData,
  handleVote,
  loading
}) => {
  const [formValid, setFormValid] = useState<boolean>(false)

  useEffect(() => {
    const requiredFields: (keyof ReducerState)[] = [
      'cat',
      'emailAddress',
      'siteName',
      'vote',
      'spamdetect'
    ]

    // Check if all required fields are present and not undefined
    const allFieldsPresent = requiredFields.every(
      field => formData[field] !== undefined
    )

    if (allFieldsPresent) {
      setFormValid(true)
    }
  }, [formData])

  return (
    <>
      <h2>
        Vote for your favourite{' '}
        {selectedCategoryObject !== undefined
          ? selectedCategoryObject.title
              .replace('Best ', '')
              .replace('&amp;', '&')
          : 'local business'}{' '}
        in {siteName.replace('&amp;', '&')}
      </h2>
      <p
        dangerouslySetInnerHTML={{
          __html:
            selectedCategoryObject !== undefined
              ? selectedCategoryObject.description
              : 'Choose a category from the list below to get started!'
        }}
      />

      {!loggedIn && (
        <>
          <input
            name="emailAddress"
            defaultValue=""
            // className={``}
            placeholder="Enter your email address"
            onChange={e => handleFieldChange(e)}
            type="text"
          />
        </>
      )}

      {(!selectedCategoryObject || showChangeCategory) && (
        <select
          name="cat"
          defaultValue={selectedCategory ?? ''}
          onChange={e => setSelectedCategory(e.target.value)}
        >
          <option disabled value="">
            Choose a category
          </option>
          {categories.map(c => (
            <option
              value={c.id}
              key={c.id}
              dangerouslySetInnerHTML={{ __html: c.title }}
            />
          ))}
        </select>
      )}

      {!showChangeCategory && (
        <button
          className={VoteStyles.ChangeCategoryButton}
          onClick={() => setShowChangeCategory(true)}
        >
          Change Category
        </button>
      )}

      {selectedCategoryObject &&
        selectedCategoryObject.finalists !== undefined && (
          <>
            {Object.entries(selectedCategoryObject.finalists).map(
              ([key, value]) => (
                <div
                  key={key}
                  className={classNames({
                    [VoteStyles.RadioSelected]: formData.vote === key,
                    [VoteStyles.Radio]: true
                  })}
                >
                  <label htmlFor={key}>{value}</label>
                  <input
                    type="radio"
                    id={key}
                    name={selectedCategoryObject.id}
                    value={key}
                    onChange={() =>
                      setFormData({
                        name: 'vote',
                        value: key
                      })
                    }
                  />
                </div>
              )
            )}

            <SpamDetector setFormData={setFormData} />
          </>
        )}

      <button
        disabled={!formValid}
        onClick={handleVote}
        className={VoteStyles.Button}
      >
        {loading ? 'Loading...' : 'Enter'}
      </button>
      <p>
        Still deciding what category to vote in? Take a look at our 21 lifestyle
        categories <Link to={`/awards/categories`}>here</Link> and put forward
        your favourite local business.
      </p>
    </>
  )
}

export default StageOne
