import React from 'react'
import {
  TwitterShareButton,
  XIcon,
  FacebookShareButton,
  FacebookIcon
} from 'react-share'
import * as NominateStyles from './VoteWidget.module.scss'

interface SocialShareProps {
  siteUrl: string
}
const SocialShare: React.FC<SocialShareProps> = ({ siteUrl }) => {
  const url = siteUrl
  const subDomain = url ? url.split('.')[0].replace('https://', '') : ''
  const directory = url ? url.split('/')[3] : ''
  const link =
    !directory || directory?.length < 1
      ? url?.replace(`//${subDomain}.`, `//`)
      : url
          ?.replace(`/${directory}`, '/')
          .replace(`//${subDomain}.`, `//${directory}.`)

  return (
    <>
      {/* <p className={NominateStyles.Subheading}>Share on Social!</p> */}
      <p>
        Make a local business owner’s day by letting them know you voted for
        them – click the icons below and don’t forget to TAG the businesses
        (because no tag, no way they’ll know).{' '}
        <span style={{ color: 'rgb(231, 44, 94)' }}>
          #MuddyAward2024 #InLoveWithLocal
        </span>
      </p>
      <div className={NominateStyles.SocialShare}>
        <TwitterShareButton url={`${link}awards/vote`}>
          <XIcon size={32} round={true} />
        </TwitterShareButton>
        <FacebookShareButton url={`${link}awards/vote`}>
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
      </div>
    </>
  )
}

export default SocialShare
