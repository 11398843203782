// eslint-disable-next-line node/no-extraneous-import
import { gql } from '@apollo/client'

export const VoteBusiness = gql`
  mutation voteBusiness($payload: String!) {
    voteBusiness(payload: $payload)
  }
`
export interface IVoteBusinessData {
  voteBusiness: string
}

export default VoteBusiness
