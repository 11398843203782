import TopBanner from '../../../components/Banners/TopBanner/TopBanner'
import Content from '../../../components/Content/Content'
import Footer from '../../../components/Footer/Footer'
import Header from '../../../components/Header/Header'
import AwardsHeader from '../../../components/MuddyAwards/AwardsHeader'
import AwardsMenu from '../../../components/MuddyAwards/AwardsMenu'
import VoteWidget from '../../../components/MuddyAwards/VoteWidget'
import Breadcrumb from '../../../components/organisms/breadcrumb/Breadcrumb'
import VideoPageMeta from '../../../components/PageMeta/VideoPageMeta'
import React, { useContext, useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import { IMenu } from '../../../graphql/entities/Menu'
import {
  GeneralSettings,
  AdStarBlock,
  Category
  // SponsoredCategories
} from '../../../types'
import { MuddyAwardsContext, MuddyAwardsStates } from '../../../lib/utils'
import Leaderboard from '../../../components/Banners/Leaderboard/Leaderboard'

export const query = graphql`
  query {
    wpgraphql {
      generalSettings {
        title
        siteId
        egSettingTwitter
        url
      }
      awardsCategories {
        title
        image
        description
        id
        finalists {
          meta_a
          meta_b
          meta_c
          meta_d
          meta_e
        }
      }
      adStarCodes {
        id
        adslot
      }
      awardsMenu: menu(id: "Awards", idType: NAME) {
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
      category(id: "muddy-awards", idType: SLUG) {
        sponsoredBy
        sponsoredLogo
      }
    }
  }
`

interface VoteProps {
  data: {
    wpgraphql: {
      generalSettings: GeneralSettings
      adStarCodes: AdStarBlock[]
      awardsMenu: {
        menuItems: {
          nodes: IMenu[]
        }
      }
      category: Category
      awardsCategories: {
        id: string
        title: string
        image: string
        description: string
        finalists: {
          meta_a: string
          meta_b: string
          meta_c: string
          meta_d: string
          meta_e: string
        }
      }[]
    }
  }
}

const Vote = ({
  data: {
    wpgraphql: {
      generalSettings,
      adStarCodes,
      awardsMenu: {
        menuItems: { nodes: subMenu }
      },
      awardsCategories,
      category
    }
  }
}: VoteProps) => {
  const AwardsContext = useContext(MuddyAwardsContext)

  useEffect(() => {
    if (generalSettings.siteId === 1) {
      navigate('/awards/counties/')
    }
  }, [])

  return (
    <>
      <VideoPageMeta
        pageData={{
          ...generalSettings,
          title: 'Muddy Stilettos Awards 2024',
          description:
            'The Muddy Stilettos Awards are the most coveted awards for independent lifestyle businesses across 28 counties, and totally FREE to enter!',
          image:
            'https://dev.muddystilettos.co.uk/awards-2024/assets/i-voted-2024-square.jpeg'
        }}
      />
      <Header />
      <TopBanner ads={adStarCodes} pageType={`awards`} />
      <Leaderboard adSlot={'TopLB'} sticky />
      {category && (
        <AwardsHeader category={category} generalSettings={generalSettings} />
      )}
      {subMenu && (
        <AwardsMenu subMenu={subMenu} generalSettings={generalSettings} />
      )}
      <div>
        <Content>
          <Breadcrumb
            postType={`awards`}
            postTitle={'/ Vote'}
            postURI={'/vote'}
          />
          <h1 dangerouslySetInnerHTML={{ __html: 'Vote' }} />
        </Content>
        {AwardsContext === MuddyAwardsStates.VOTING ? (
          <VoteWidget
            siteUrl={generalSettings.url ?? ''}
            siteName={generalSettings.title ?? 'your County'}
            categories={awardsCategories}
          />
        ) : (
          <Content>
            <p>
              Voting has now closed. Winners will be announced 2nd May 2024.
              Watch this space!
            </p>
          </Content>
        )}
      </div>
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default Vote
