import React from 'react'

type VoteErrorProps = {
  voteError: string | undefined
  resetVoteData: () => void
}

const VoteError: React.FC<VoteErrorProps> = ({ voteError, resetVoteData }) => {
  return (
    <>
      <h2>Oh no!</h2>
      <p>
        {voteError}
        {/* <span
          style={{
            color: '#d41a50',
            cursor: 'pointer',
            textDecoration: 'underline'
          }}
          onClick={() => {
            resetVoteData()
          }}
        >
          here.
        </span> */}
      </p>
    </>
  )
}

export default VoteError
